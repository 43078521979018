import './App.css';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Login from './components/Login';
import Main from './components/Main';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/main" element={<Main />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
