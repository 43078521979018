import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import "./Main.css";
import axiosInstance from '../request'



const Main = () => {
	const navigate = useNavigate()
	const [name, setName] = useState("");
	const [groupId, setGroupId] = useState("");
	const [groupchat, setGroupChat] = useState([])
	
	const handleNameChange = (event) => {
		setName(event.target.value);
	};
	
	const handleGroupIdChange = (event) => {
		setGroupId(event.target.value);
	};
	
	
	
	const fetchData = () => {
		axiosInstance.get('/groupchat')
		.then((res) => {
			setGroupChat(res.data)
		})
		.catch(err => console.log(err))
		
	}
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			await axiosInstance.post("/groupchat-create", {
				name: name,
				groupId: groupId,
			});
			fetchData()
			setName('')
			setGroupId('')

		} catch (err) {
			console.log(err)
		}
	};
	const handleDelete = async (groupName) => {
		try {
			await axiosInstance.post("/delete/" + groupName)
			fetchData()
		} catch(err) {
			console.log(err)
		}
	}

	useEffect(() => {
		const logged = window.localStorage.getItem("logged") === "true";
		if (logged) {
			navigate("/main");
		} else {
			navigate("/");
		}

		fetchData()
	}, [])
	
	return (
		<div className="main-container">

		<div className="container">
			<div className="firstCont">
				<div class="row">
					<div class="input-field col s6">
						<input
							placeholder="Name"
							id="first_name"
							type="text"
							class="validate"
							value={name}
							onChange={handleNameChange}
						/>
					</div>
					<div class="input-field col s6">
						<input value={groupId} placeholder="GroupId" id="last_name" type="text" class="validate"  onChange={handleGroupIdChange}/>
					</div>
					<button class="btn waves-effect green darken-2" type="submit" name="action" onClick={handleSubmit}>
						Create
					</button>
				</div>

			</div>
            <div className="secondCont">
				<table>
					<thead>
					<tr>
						<th>Name</th>
						<th>Group Id</th>
					</tr>
					</thead>

					<tbody>
						
						{groupchat.map((e) => {
							return (
								<tr>
									<td>{e.name}</td>
									<td>{e.groupId}</td>
									<button class="btn waves-effect red" type="submit" name="action" onClick={() => handleDelete(e.name)} style={{marginTop: '5px', marginRight: '-50px'}}>
										Delete
									</button>
								</tr>	
							)
						})}
					</tbody>
				</table>
            </div>
		</div>
		</div>
	);
};

export default Main;
