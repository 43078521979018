import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import 'materialize-css/dist/css/materialize.min.css';
import './Login.css'
import axiosInstance from "../request";

const Login = () => {
	const navigate = useNavigate();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const handleUsernameChange = (event) => {
		setUsername(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

    const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			await axiosInstance.post("/auth/login", {
				name: username,
				pass: password,
			});
			localStorage.setItem("logged", "true");
			navigate("/main");
		} catch (err) {
            console.log(err)
		}
	};

	useEffect(() => {
		const logged = window.localStorage.getItem("logged") === "true";
		navigate("/main");
		if (logged) {
		} else {
			navigate("/");
		}
	}, []);

    return (
        <div className="login-container">
            <div className="login-form">
                <div class="row">
                    <div class="input-field col s6">
                        <textarea onChange={handleUsernameChange} id="textarea1" class="materialize-textarea" placeholder="Name"></textarea>
                    </div>
                    <div class="input-field col s6">
                        <input onChange={handlePasswordChange} id="password" type="password" class="validate" placeholder="Password"/>
                    </div>
                </div>
                <div className="button">
                    <button class="btn waves-effect light-green darken-1" type="submit" name="action" onClick={handleSubmit}>Login
                        <i class="material-icons right">send</i>
                    </button>

                </div>
            </div>
        </div>
	);
};

export default Login;